import '@hallow-inc/ui/styles.css';
import 'react-multi-carousel/lib/styles.css';
import '../styles/globals.scss';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { AppProps } from 'next/app';
import BugsnagErrorBoundary from '@providers/BugsnagErrorBoundary';
import { buildLDUserContext } from '@utilities/featureFlagger';
import FeatureFlaggerProvider from '@providers/FeatureFlaggerProvider/FeatureFlaggerProvider';
import { getApiCookie } from '@utilities/cookies';
import { getPersistor } from '@rematch/persist';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GTMProvider from '@providers/GTMProvider';
import IntlProvider from '@providers/IntlProvider';
import Layout from 'layouts/site-layout/Layout';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'store';
import { useRouter } from 'next/router';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { alignAnonymousids, saveUrlQueries } from '@utilities/functions';
import { apiAuthRefresh, apiGetOldPlans, apiGetPlans, apiGetUserData } from '@utilities/api';
import { ContextProvider, ReactQueryProvider } from '@v2';
import React, { useEffect } from 'react';

/*
    Setup Hallow API Url (for dev/testing)
*/
const apiCookie = typeof document !== 'undefined' ? getApiCookie(document.cookie) : null;
export const api = { url: apiCookie ?? process.env.ACCESS_API_HALLOW };

/*
    Setup Redux Store
*/
const { dispatch } = store;
const persistor = getPersistor();

/*
    Load Segment for Analytics
*/
export let segment;

const HallowAccess = ({ Component, pageProps }: AppProps) => {
    const router = useRouter();

    useEffect(() => {
        const setupStore = async () => {
            try {
                dispatch.user.setIsUserUpdating(true);
                await apiAuthRefresh();
                await apiGetUserData();
                await apiGetPlans();
                // get default trialDays offer from backend
                await apiGetOldPlans();
            } catch (e) {
                // at least this is only one error instead of 3 every time we trigger this
                console.error(e);
            }
        };
        setupStore();
        segment = AnalyticsBrowser.load({ writeKey: process.env.ACCESS_SEGMENT_KEY! });
        if (segment) segment.setAnonymousId(alignAnonymousids());
    }, []);

    useEffect(() => {
        if (!router.isReady) return;

        saveUrlQueries(router.query, dispatch);
    }, [router.isReady, router.query]);

    return (
        <ReduxProvider store={store}>
            <BugsnagErrorBoundary>
                <PersistGate persistor={persistor}>
                    <ReactQueryProvider>
                        <IntlProvider>
                            <FeatureFlaggerProvider>
                                <GTMProvider>
                                    <GoogleOAuthProvider clientId={process.env.ACCESS_GOOGLE_CLIENT_ID}>
                                        <ContextProvider>
                                            <Layout Component={Component} pageProps={pageProps} />
                                        </ContextProvider>
                                    </GoogleOAuthProvider>
                                </GTMProvider>
                            </FeatureFlaggerProvider>
                        </IntlProvider>
                    </ReactQueryProvider>
                </PersistGate>
            </BugsnagErrorBoundary>
        </ReduxProvider>
    );
};

export default withLDProvider({
    clientSideID: process.env.ACCESS_LAUNCH_DARKLY_CLIENT_ID,
    context: buildLDUserContext(),
    options: { streaming: true }
})(HallowAccess);