import { apiUpdateUser } from '@utilities/api';
import Button from '@components/Button';
import ConsentCheckbox from '@components/ConsentCheckbox/ConsentCheckbox';
import ConsentPopup from '@components/ConsentPopup';
import { COOKIE_POLICY_URL } from '@utilities/urls';
import { getCookieDomain } from '@utilities/vercel';
import HallowCookies from '@models/Cookies';
import s from './consentManager.module.scss';
import { saveBrowserConsent } from '@utilities/cookies';
import { selectUser } from '@store/userModel';
import Swal from 'sweetalert2';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import XButton from '@components/XButton';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';

const ConsentManager = () => {
    const intl = useIntl();
    const router = useRouter();

    const user = useSelector(selectUser);
    const [consentArray, setConsentArray] = useState<number[]>([1, 2, 3]);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const consentManagerRef = useRef<HTMLDivElement>(null);
    const consentPopupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        window.addEventListener('hashchange', (e) => {
            if (e.newURL.endsWith('cookie-manager')) {
                e.preventDefault();
                e.stopPropagation();

                // match consent checkboxes to localStorage consent
                updateConsent();
            // if consent is present when we close manager, hide popup
            } else if (JSON.parse(localStorage.getItem('consent')) && consentPopupRef.current) {
                consentPopupRef.current.style.display = 'none';
            }

            if (consentManagerRef.current) updateManagerDisplay(e.newURL);
        });

        // handle page refresh
        if (consentManagerRef.current) updateManagerDisplay();

        // ensure localStorage and cookie consent values match
        // if both exist and do not match, defer to cookie
        let consentLocal = JSON.parse(localStorage.getItem('consent'));
        let consentCookie;
        const dividedCookieArray = `; ${ document.cookie }`.split(`; ${ HallowCookies.CONSENT }=`);
        if (dividedCookieArray.length === 2) {
            consentCookie = JSON.parse(dividedCookieArray.pop().split(';').shift());
        }

        // Handle Old Consent Values
        const convertToArray = (newArrayValue) => {
            consentLocal = newArrayValue;
            localStorage.setItem('consent', JSON.stringify(newArrayValue));
            consentCookie = newArrayValue;
            document.cookie = `${ HallowCookies.CONSENT }=${ JSON.stringify(newArrayValue) }; expires=${ new Date('1/1/2060') }; domain=.hallow.com; path=/;`;
        };

        if (!Array.isArray(consentLocal)) {
            if (consentLocal === 1) convertToArray([2]);
            else if (consentLocal === 2) convertToArray([1]);
            else if (consentLocal === 3) convertToArray([1, 2]);
            else if (consentLocal === 4) convertToArray([3]);
            else if (consentLocal === 5) convertToArray([2, 3]);
            else if (consentLocal === 6) convertToArray([1, 3]);
            else if (consentLocal === 7) convertToArray([1, 2, 3]);
        }

        // Set and Match both localStorage and cookie
        if ((consentCookie && consentLocal && consentCookie !== consentLocal)
            || (consentCookie && !consentLocal)
            || (!consentCookie && consentLocal)) {
            if (consentCookie) {
                localStorage.setItem('consent', JSON.stringify(consentCookie));
            } else if (process.env.ACCESS_AUTH_HEADER) {
                document.cookie = `${ HallowCookies.CONSENT }=${ JSON.stringify(consentLocal) }; expires=${ new Date('1/1/2060') }; domain=${ getCookieDomain(window?.location?.host) }; path=/;`;
            } else {
                document.cookie = `${ HallowCookies.CONSENT }=${ JSON.stringify(consentLocal) }; expires=${ new Date('1/1/2060') }; domain=.hallow.com; path=/;`;
            }
        }
        updateConsent();
    }, []);

    useEffect(() => {
        if (consentManagerRef.current) updateManagerDisplay();
    }, [consentManagerRef.current]);

    const updateManagerDisplay = (url = window.location.hash) => {
        if (url.endsWith('cookie-manager')) consentManagerRef.current.style.display = 'flex';
        else consentManagerRef.current.style.display = null;
    };

    const updateConsent = () => {
        const curWindowConsent = JSON.parse(localStorage.getItem('consent'));
        if (curWindowConsent) {
            setConsentArray(curWindowConsent);
        } else {
            setConsentArray([1, 2, 3]);
        }
    };

    const updateConsentArray = (isChecked, value) => {
        const newConsentArray = [...consentArray];

        if (isChecked) {
            if (!newConsentArray.includes(value)) {
                newConsentArray.push(value);
            }
        } else if (newConsentArray.includes(value)) {
            const idx = newConsentArray.indexOf(value);
            newConsentArray.splice(idx, 1);
        }

        newConsentArray.sort();
        setConsentArray(newConsentArray);
        sessionStorage.setItem('hasConsentUpdated', 'true');
    };

    const onSubmit = async (e) => {
        setIsSaving(true);
        e.preventDefault();
        e.stopPropagation();

        try {
            saveBrowserConsent(JSON.stringify(consentArray));
            const consent = consentArray;
            if (user?.id) {
                await apiUpdateUser({ consent });
            }

            await Swal.fire({
                titleText: intl.formatMessage({ id: 'general_word_success', defaultMessage: 'Success' }),
                text: intl.formatMessage({ id: 'alert_title_preferences_saved', defaultMessage: 'Preferences Saved!' }),
                icon: 'success',
                confirmButtonText: intl.formatMessage({ id: 'general_word_ok', defaultMessage: 'Ok' }),
                buttonsStyling: false,
                customClass: { confirmButton: 'hallowButton alertButton' }
            });

            setIsSaving(false);
            router.back();
        } catch (err) {
            setIsSaving(false);
            Swal.fire({
                titleText: intl.formatMessage({ id: 'general_word_error', defaultMessage: 'Error' }),
                text: intl.formatMessage({ id: 'alert_message_failed_to_save', defaultMessage: 'Failed to save preferences. Please try again.' }),
                icon: 'error',
                confirmButtonText: intl.formatMessage({ id: 'general_word_ok', defaultMessage: 'Ok' }),
                buttonsStyling: false,
                customClass: { confirmButton: 'hallowButton alertButton' }
            });
        }
    };

    return (
        <>
            <section ref={consentManagerRef} className={s.consentManager}>
                <header className={s.consentHeader}>
                    <XButton />
                </header>
                <div className={s.consentContainer}>
                    <div className={s.descriptionContainer}>
                        <h2 className={s.title}>
                            <FormattedMessage id={'cookie_manager_title'} defaultMessage={'🍪 Cookies'} />
                        </h2>
                        <p>
                            <FormattedMessage
                                id={'cookie_manager_description'}
                                defaultMessage={`When you visit our website, we may store cookies on your browser for your security and to help us improve your experience on the website and in the app. The information does not usually identify you directly, but it can give you a safer and more personalized experience. Because we respect your right to privacy, you are able to choose not to allow some types of cookies. Blocking some types of cookies may affect your experience on the site. See our ${ <a href={COOKIE_POLICY_URL} target="_blank" className={s.policyLink} rel="noreferrer">{intl.formatMessage({ id: 'cookie_manager_policy_link_lowercase', defaultMessage: 'cookie policy' })}</a> } for more details on our cookies.`}
                                values={{
                                    0: <a href={COOKIE_POLICY_URL} target="_blank" className={s.policyLink} rel="noreferrer">{
                                        intl.formatMessage({ id: 'cookie_manager_policy_link_lowercase', defaultMessage: 'cookie policy' })
                                    }</a>
                                }}
                            />
                        </p>
                    </div>
                    <div className={s.formContainer}>
                        <form>
                            <ConsentCheckbox
                                id={'necessary-approval'}
                                isChecked={true}
                                disabled={true}
                                onCheck={updateConsentArray}
                                title={'cookie_manager_necessary_cookie_title'}
                                value={0}
                                description={'cookie_manager_necessary_cookie_para'}
                            />
                            <ConsentCheckbox
                                id={'performance-approval'}
                                isChecked={consentArray.includes(2)}
                                disabled={false}
                                onCheck={updateConsentArray}
                                title={'cookie_manager_performance_cookie_title'}
                                value={2}
                                description={'cookie_manager_performance_cookie_para'}
                            />
                            <ConsentCheckbox
                                id={'functional-approval'}
                                isChecked={consentArray.includes(1)}
                                disabled={false}
                                onCheck={updateConsentArray}
                                title={'cookie_manager_functional_cookie_title'}
                                value={1}
                                description={'cookie_manager_functional_cookie_para'}
                            />
                            <ConsentCheckbox
                                id={'targeting-approval'}
                                isChecked={consentArray.includes(3)}
                                disabled={false}
                                onCheck={updateConsentArray}
                                title={'cookie_manager_targeting_cookie_title'}
                                value={3}
                                description={'cookie_manager_targeting_cookie_para'}
                            />
                            <Button
                                buttonClass={s.submitButton}
                                loading={isSaving}
                                onClick={onSubmit}
                                text={'button_save'}
                                type={'submit'}
                            />
                        </form>
                    </div>
                </div>
            </section>
            <ConsentPopup consentPopupRef={consentPopupRef} />
        </>
    );
};

export default ConsentManager;